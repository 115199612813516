export const companyTypesOptions = [
    {
      id: 1,
      label: 'Select Company Type',
      value: 'Select Company Type',
    },
    {
      id: 2,
      label: 'Industry',
      value: 'Industry',
    },
    {
      id: 3,
      label: 'Agriculture',
      value: 'Agriculture',
    },
    {
      id: 4,
      label: 'Automobile Transport',
      value: 'Automobile Transport',
    },
    {
      id: 5,
      label: 'Ecommerce',
      value: 'Ecommerce',
    },
    {
      id: 6,
      label: 'Financial Institution',
      value: 'Financial Institution',
    },
    {
      id: 7,
      label: 'Education',
      value: 'Education',
    },
    {
      id: 8,
      label: 'Gym',
      value: 'Gym',
    },
    {
      id: 9,
      label: 'Hospitality',
      value: 'Hospitality',
    },
    {
      id: 10,
      label: 'IT Company',
      value: 'IT Company',
    },
    {
      id: 11,
      label: 'Lifestyle Clubs',
      value: 'Lifestyle Clubs',
    },
    {
      id: 12,
      label: 'Logistics',
      value: 'Logistics',
    },
    {
      id: 13,
      label: 'Marriage Bureau',
      value: 'Marriage Bureau',
    },
    {
      id: 14,
      label: 'Media Advertisement',
      value: 'Media Advertisement',
    },
    {
      id: 15,
      label: 'Personal Use',
      value: 'Personal Use',
    },
    {
      id: 16,
      label: 'Political',
      value: 'Political',
    },
    {
      id: 17,
      label: 'Public Sector',
      value: 'Public Sector',
    },
    {
      id: 18,
      label: 'Real estate',
      value: 'Real estate',
    },
    {
      id: 19,
      label: 'Retail FMCG',
      value: 'Retail FMCG',
    },
    {
      id: 20,
      label: 'Stock and Commodity',
      value: 'Stock and Commodity',
    },
    {
      id: 21,
      label: 'Telecom',
      value: 'Telecom',
    },
    {
      id: 22,
      label: 'Tips And Alert',
      value: 'Tips And Alert',
    },
    {
      id: 23,
      label: 'Travel',
      value: 'Travel',
    },
    {
      id: 24,
      label: 'Wholesalers Distributors or Manufacturers',
      value: 'Wholesalers Distributors or Manufacturers',
    },
  ];

  export const companySizeOptions = [
    {
      id: 1,
      label: 'Select Company Size',
      value: 'Select Company Size',
    },
    {
      id: 2,
      label: 'Startup',
      value: '0-2',
    },
    {
      id: 3,
      label: '3-5 Employees',
      value: '3-5',
    },
    {
      id: 4,
      label: '6-10 Employees',
      value: '6-10',
    },
    {
      id: 5,
      label: '11-30 Employees',
      value: '11-30',
    },
    {
      id: 6,
      label: '31-50 Employees',
      value: '31-50',
    },
    {
      id: 7,
      label: '50+ Employees',
      value: '50+',
    },
  ];