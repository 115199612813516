import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link, navigate } from "gatsby";
import logo from "../images/Logo.png";
import checkBox from "../images/check_box.png";
import check from "../images/check.png";
import topImg from "../../src/images/landing_page_Img.png";
import Icon1 from "../../src/images/icon_1.png";
import Icon2 from "../../src/images/icon_2.png";
import Icon3 from "../../src/images/icon_3.png";
import Icon4 from "../../src/images/icon_4.png";
import { companyTypesOptions, companySizeOptions } from "../components/config";
import { CountryArray } from "../components/constants";
import ISO9001 from "../images/iso-9001-seal.svg";
import ISO27001 from "../images/iso-27001-seal.svg";
import Faq from "../components/landingPage/Faq";
import Reviews from "../components/landingPage/Reviews";
import TextTransition, { presets } from "react-text-transition";
import Loader from "../components/loader";
import Seo from "../components/seo";
import Lottie from "react-lottie-player";
import lottieJson from "../images/lotties/signup-page-lottie.json";

const SignUp = () => {
  const [userProfile, setUserProfile] = useState("1");
  const [userData, setUserData] = useState([]);
  const [error, setError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const myRef = useRef(null);

  const TEXTS = [
    "Track Number of Clicks",
    "Make Your Brand More Visible",
    "Routing Traffic To Your Website",
    "Secure Audience Trust",
    "Easy To Find Content",
  ];

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      4000 // every 5 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    const formValues = {
      name: data.fullName,
      email: data.email,
      password: data.password,
      password_confirmation: data.confirmPassword,
      usage_type: data.usage,
      phone: data.phoneNumber,
      company_name: data.companyName,
      company_type: data.companyType,
      company_size: data.companySize,
    };

    fetch("https://api.teenyfy.com/api/v1/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    })
      .then(function (response) {
        return response.text();
      })
      .then(function (data) {
        setUserData(JSON.parse(data));
        if (JSON.parse(data).token) {
          navigate("https://app.teenyfy.com/user/login");

          if (formValues) {
            setIsLoading(true);
          } else {
            setIsLoading(false);
          }
        }
      });
  };

  userData?.error?.message?.errors ? setError(true) : setError(false);

  const handleUserProfile = (e) => {
    setUserProfile(e.target.value);
  };

  const handleTop = () => {
    myRef.current.scrollIntoView();
    // window.scrollTo({
    //   left: 0,
    //   top: document.body.scrollHeight,
    //   behavior: "smooth",
    // });
  };

  return (
    <>
      <Seo slug="/signup" />
      <div className="overflow-x-hidden">
        <div className="bg-white items-center w-full mx-auto">
          <div className="container px-5 py-24 mx-auto">
            <div className="md:block">
              <img
                className="w-36 h-10 relative bottom-14"
                src={logo}
                alt="error"
              />
            </div>
            <div className="md:flex md:items-center md:justify-between">
              <div className="md:w-1/2">
                <h1 className="font-[400] text-[48px] text-[#1F1F39] leading-[58px] font-roboto">
                  Make Your Links More
                </h1>
                <h1 className="font-[400] text-[48px] text-[#1F1F39] leading-[58px] font-roboto">
                  {" "}
                  Memorable
                </h1>
                <p className="text-lg mt-4 sm:text-[#1F1F39] font-2xl">
                  Shorten your urls into branded , readable format
                  <p>
                    {" "}
                    with{" "}
                    <Link to="/" className="text-dark-green">
                      Teenyfy
                    </Link>
                  </p>
                </p>
                <div className="lg:flex sm:grid">
                  <div className="">
                    <img className="relative top-4" src={checkBox} alt="" />
                    <img className="relative" src={check} alt="" />
                    <p className="relative left-6 bottom-4 text[#212121]">
                      No Credit Card Required
                    </p>
                  </div>
                  <div className="items-start">
                    <img
                      className="relative top-4 lg:left-14"
                      src={checkBox}
                      alt=""
                    />
                    <img className="relative lg:left-14" src={check} alt="" />
                    <p className="relative lg:left-20 left-[26px] bottom-4 text-[#212121]">
                      Data Protection
                    </p>
                  </div>
                </div>
                <button
                  onClick={handleTop}
                  className=" bg-dark-green text-white w-48 h-12 rounded-[40px] leading-[96px]"
                >
                  <p className="mb-8 text-lg text-center mt-2.5">
                    {" "}
                    Sign Up For Free{" "}
                  </p>
                </button>
              </div>
              <div className="w-[320px] lg:w-[640px] px-4 my-auto">
                <Lottie
                  loop
                  animationData={lottieJson}
                  play
                  className=" md:mt-[30px] mt-8"
                />
                {/* <img src={topImg} alt="" className=" md:mt-[30px] mt-8" /> */}
              </div>
            </div>
          </div>
          <div className="mx-auto w-11/12 3xl:w-10/12 py-4">
            <p className="text-black font-normal text-[32px] text-center mb-8 py-6">
              Benefits of using a short URL
            </p>
            <div className="flex  flex-col sm:flex-row flex-wrap gap-y-4 text-xl 2xl:text-2xl gap-x-3 2xl:gap-x-7 mx-auto 3xl:w-11/12 justify-center items-center">
              <p className="border border-primary text-primary px-6 py-3 w-full sm:w-auto rounded-lg  text-center flex justify-center items-center">
                Track Number of Clicks
              </p>
              <p className="border border-primary text-primary px-6 py-3 w-full sm:w-auto rounded-lg  text-center flex justify-center items-center">
                Track The Time Of Clicks and Location
              </p>
              <p className="border border-primary text-primary px-6 py-3 w-full sm:w-auto rounded-lg  text-center flex justify-center items-center">
                Make Your Brand More Visible
              </p>

              <p className="border border-primary text-primary px-6 py-3 w-full sm:w-auto rounded-lg  text-center flex justify-center items-center xl:ml-[130px] 2xl:ml-[145px] 3xl:ml-[211px]">
                Routing Traffic To Your Website
              </p>
              <p className="border border-primary text-primary px-6 py-3 w-full sm:w-auto rounded-lg  text-center flex justify-center items-center">
                Attract Audience By Using Words-Of-Mouth
              </p>
              <p className="border border-primary text-primary px-6 py-3 w-full sm:w-auto rounded-lg  text-center flex justify-center items-center">
                Secure Audience Trust
              </p>

              <p className="border border-primary text-primary px-6 py-3 w-full sm:w-auto rounded-lg  text-center flex justify-center items-center">
                Incorporate Short Video and Screenshots With Ease
              </p>
              <p className="border border-primary text-primary px-6 py-3 w-full sm:w-auto rounded-lg  text-center flex justify-center items-center">
                Increase Clicks By Using Reader-Friendly Words
              </p>
              <p className="border border-primary text-primary px-6 py-3 w-full sm:w-auto rounded-lg  text-center flex justify-center items-center">
                Easy To Find Content
              </p>
            </div>
          </div>
          <Reviews />
          <div className="container mx-auto text-center px-5">
            <div className="font-[400] md:w-full md:h-[38px] text-[32px] font-roboto text-[#1F1F39] ">
              Few Awesome Features
            </div>

            <div className="text-gray-600 body-font ">
              <div className="container px-5 py-10 ">
                <div className="gap-8 grid md:grid-cols-2 lg:grid-cols-4 ">
                  <div className="w-full p-4 bg-[#EAFAFA] rounded-3xl ">
                    <div className="min-h-[400px] py-6">
                      <div className="min-h-[85px]">
                        <img
                          src={Icon1}
                          alt=""
                          className="m-auto text-center align-middle"
                        />
                      </div>
                      <h2 className="font-[400] text-[20px] leading-[28px] font-roboto text-[#1F1F39]">
                        Link management
                      </h2>
                      <p className="flex-grow leading-relaxed text-base text-left pt-[20px] px-[50px]">
                        <ul className="list-outside list-disc">
                          <li className="font-[400] text-[16px] leading-[24px] text-[#636973] text-opacity-50 font-roboto">
                            Fast Redirects
                          </li>
                          {/* <li className="font-[400] text-[16px] leading-[24px] text-[#636973] text-opacity-50 font-roboto">
                            Editable destination URLs
                          </li> */}
                          <li className="font-[400] text-[16px] leading-[24px] text-[#636973] text-opacity-50 font-roboto">
                            Custom URL slugs (vanity-url)
                          </li>
                          <li className="font-[400] text-[16px] leading-[24px] text-[#636973] text-opacity-50 font-roboto">
                            UTM presets
                          </li>
                          <li className="font-[400] text-[16px] leading-[24px] text-[#636973] text-opacity-50 font-roboto">
                            Bulk link creation/edit
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                  <div className="w-full p-2 bg-[#EAFAFA] rounded-3xl">
                    <div className="min-h-[400px] border-2 border-[#EAFAFA] py-6">
                      <div className="min-h-[85px]">
                        <img
                          src={Icon2}
                          alt=""
                          className="m-auto text-center align-middle"
                        />
                      </div>

                      <h2 className="font-[400] text-[20px] leading-[28px] font-roboto text-[#1F1F39]">
                        Analytics
                      </h2>
                      <p className="leading-relaxed text-base text-left pt-[20px] px-[50px]">
                        <li className="font-[400] text-[16px] leading-[24px] text-[#636973] text-opacity-50 font-roboto">
                          Link analytics
                        </li>
                        <li className="font-[400] text-[16px] leading-[24px] text-[#636973] text-opacity-50 font-roboto">
                          UTM builder
                        </li>
                        <li className="font-[400] text-[16px] leading-[24px] text-[#636973] text-opacity-50 font-roboto">
                          Link search
                        </li>
                      </p>
                    </div>
                  </div>
                  <div className="w-full p-4 bg-[#EAFAFA] rounded-3xl">
                    <div className="min-h-[400px] border-2 border-[#EAFAFA] py-6 text-center">
                      <div className="min-h-[85px]">
                        <img
                          src={Icon3}
                          alt=""
                          className="m-auto text-center align-middle"
                        />
                      </div>

                      <h2 className="font-[400] text-[20px] leading-[28px] font-roboto text-[#1F1F39]">
                        Domain name management
                      </h2>
                      <p className="leading-relaxed text-base text-left pt-[20px] px-[50px]">
                        <ul className="list-outside list-disc">
                          <li className="font-[400] text-[16px] leading-[24px] text-[#636973] text-opacity-50 font-roboto">
                            Multiple domain names
                          </li>
                          <li className="font-[400] text-[16px] leading-[24px] text-[#636973] text-opacity-50 font-roboto">
                            Https (SSL)
                          </li>
                          <li className="font-[400] text-[16px] leading-[24px] text-[#636973] text-opacity-50 font-roboto">
                            Main domain redirect
                          </li>
                          <li className="font-[400] text-[16px] leading-[24px] text-[#636973] text-opacity-50 font-roboto">
                            404 redirect management
                          </li>
                          <li className="font-[400] text-[16px] leading-[24px] text-[#636973] text-opacity-50 font-roboto">
                            Custom SSL
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                  <div className="w-full p-4 bg-[#EAFAFA] rounded-3xl">
                    <div className="min-h-[400px] border-2 border-[#EAFAFA] py-6">
                      <div className="min-h-[85px]">
                        <img
                          src={Icon4}
                          alt=""
                          className="m-auto text-center align-middle"
                        />
                      </div>
                      <h2 className="font-[400] text-[20px] leading-[28px] font-roboto text-[#1F1F39]">
                        Boost Results
                      </h2>
                      <p className="leading-relaxed text-base text-left pt-[20px] px-[50px]">
                        <ul className="list-outside list-disc">
                          <li className="font-[400] text-[16px] leading-[24px] text-[#636973] text-opacity-50 font-roboto">
                            Better Delivery
                          </li>
                          <li className="font-[400] text-[16px] leading-[24px] text-[#636973] text-opacity-50 font-roboto">
                            Improved Tracking
                          </li>
                          <li className="font-[400] text-[16px] leading-[24px] text-[#636973] text-opacity-50 font-roboto">
                            Proactive Planning
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          ref={myRef}
          className="container mx-auto pb-20 md:mt-16 mt-4"
          id="signup-for-free"
        >
          <p className="font-normal text-[32px] leading-[46.88px] font-Roboto justify-center px-16 text-center text-[#1F1F39]">
            Sign Up for Free, No Credit Card required
          </p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-center items-center mt-12 md:mt-8 px-3 md:pb-4 ">
              <div className="flex md:w-[550px] w-full justify-center gap-y-10 bg-white rounded-[20px] overflow-hidden mx-auto flex-col p-3 md:px-24 md:pb-32 md:pt-8 border-solid border-2 border-dark-green">
                <h3 className="text-[18px] font-[400] font-Montserrat">
                  Register
                </h3>

                <div className="relative h-10 input-component">
                  <input
                    {...register("fullName", { required: true })}
                    className="h-[49px] border-[#E1E1E1] border-[1px] w-full px-2"
                  />

                  {errors.fullName?.type === "required" && (
                    <p className="text-red-500 text-xs mt-1">
                      Name field is required*
                    </p>
                  )}

                  <label
                    htmlFor="fullName"
                    className="absolute left-2 transition-all bg-white px-1 register-label font-Montserrat font-[300] text-base text-[#E1E1E1]"
                  >
                    Full Name
                  </label>
                </div>
                <div className="relative h-10 mt-2 input-component">
                  <input
                    type="text"
                    {...register("email", { required: true })}
                    className="h-[49px] border-[#E1E1E1] border-[1px] w-full px-2"
                  />

                  {errors.email?.type === "required" && (
                    <p className="text-red-500 text-xs mt-1">
                      Email field is required*
                    </p>
                  )}

                  {error && (
                    <p className="text-red-500 text-xs mt-1">
                      Email Already Registered*
                    </p>
                  )}
                  <label
                    htmlFor="Email"
                    className="absolute left-2 bg-white px-1 register-label font-Montserrat font-[300] text-base text-[#717171]"
                  >
                    Email
                  </label>
                </div>

                <div className="relative mt-2  h-10 input-component">
                  <select
                    {...register("country")}
                    id="name"
                    class="border-[#E1E1E1] border-[1px] text-sm block w-full p-2.5 text-[#717171] py-4 bg-transparent outline-none"
                  >
                    {CountryArray.map((items, name) => {
                      return <option key={name}>{items.name}</option>;
                    })}
                  </select>
                  <label
                    htmlFor="countryType"
                    className="absolute left-2 bg-white px-1 register-label font-Montserrat font-[300] text-base text-[#717171]"
                  >
                    Country
                  </label>
                </div>

                <div className="relative h-10 input-component">
                  <input
                    type="password"
                    {...register("password", { required: true })}
                    className="h-[49px] border-[#E1E1E1] border-[1px] w-full px-2"
                  />
                  {errors.password?.type === "required" && (
                    <p className="text-red-500 text-xs mt-1">
                      Password field is required*
                    </p>
                  )}

                  {passwordError && (
                    <p className="text-red-500 text-xs mt-1">
                      Password does not match*
                    </p>
                  )}

                  <label
                    htmlFor="Password"
                    className="absolute left-2 bg-white px-1 register-label font-Montserrat font-[300] text-base text-[#717171]"
                  >
                    Password
                  </label>
                </div>
                <div className="relative mt-2 h-10 input-component">
                  <input
                    type="password"
                    {...register("confirmPassword", { required: true })}
                    className="h-[49px] border-[#E1E1E1] border-[1px] w-full px-2"
                  />

                  {errors.confirmPassword?.type === "required" && (
                    <p className="text-red-500 text-xs mt-1">
                      Confirm Password field is required*
                    </p>
                  )}
                  {passwordError && (
                    <p className="text-red-500 text-xs mt-1">
                      Please make sure your password match *
                    </p>
                  )}
                  <label
                    htmlFor="confirmPassword"
                    className="absolute left-2 bg-white px-1 register-label font-Montserrat font-[300] text-base text-[#717171]"
                  >
                    Confirm Password
                  </label>
                </div>
                <div className="relative mt-2 h-10 input-component">
                  <input
                    type={"number"}
                    {...register("phoneNumber", { required: true })}
                    className="h-[49px] border-[#E1E1E1] border-[1px] w-full px-2"
                  />

                  {errors.phoneNumber?.type === "required" && (
                    <p className="text-red-500 text-xs mt-1">
                      {" "}
                      Phone Number field is required*{" "}
                    </p>
                  )}
                  <label
                    htmlFor="phoneNumber"
                    className="absolute left-2 bg-white px-1 register-label font-Montserrat font-[300] text-base text-[#E1E1E1]"
                  >
                    Phone Number
                  </label>
                </div>
                <div className="relative h-10 input-component">
                  <p className="mb-2 text-[#717171]">
                    How will you use your links?
                  </p>
                  <div class="inline-flex items-center mb-4 mr-4">
                    <input
                      {...register("usage", { required: true })}
                      id="personal"
                      type="radio"
                      value="1"
                      checked={userProfile === "1"}
                      className="w-4 h-4 bg-gray-100 border-gray-300"
                      onClick={(e) => handleUserProfile(e)}
                    />
                    <label
                      htmlFor="personal"
                      class="ml-2 text-sm font-medium text-[#717171] dark:text-gray-300"
                    >
                      Personal
                    </label>
                  </div>
                  <div class="inline-flex items-center">
                    <input
                      {...register("usage", { required: true })}
                      id="company"
                      type="radio"
                      value="2"
                      checked={userProfile === "2"}
                      className="w-4 h-4 bg-gray-100 border-gray-300"
                      onClick={(e) => handleUserProfile(e)}
                    />
                    <label
                      htmlFor="company"
                      class="ml-2 text-sm font-medium text-[#717171] dark:text-gray-300"
                    >
                      Company
                    </label>
                  </div>
                </div>

                {userProfile === "2" && (
                  <>
                    <div className="relative h-10 input-component ">
                      <input
                        {...register("companyName", { required: true })}
                        className="h-[49px] border-[#E1E1E1] border-[1px] w-full px-2"
                      />

                      {errors.phoneNumber?.type === "required" && (
                        <p className="text-red-500 text-xs mt-1">
                          {" "}
                          Company Name field is required*{" "}
                        </p>
                      )}
                      <label
                        htmlFor="companyName"
                        className="absolute left-2 focus:border-[#E1E1E1] bg-white px-1 register-label font-Montserrat font-[300] text-base text-[#717171] "
                      >
                        Company Name
                      </label>
                    </div>

                    <div className="relative mt-2 h-10 input-component">
                      <select
                        {...register("companyType")}
                        id="company_type"
                        class="border-[#E1E1E1] border-[1px]  text-sm block w-full p-2.5 text-[#717171] py-4 bg-transparent outline-none"
                      >
                        {companyTypesOptions.map((items, id) => {
                          return <option key={id}>{items.value}</option>;
                        })}
                      </select>
                      <label
                        htmlFor="companyType"
                        className="absolute left-2 bg-white px-1 register-label font-Montserrat font-[300] text-base text-[#717171]"
                      >
                        Company Type
                      </label>
                    </div>

                    <div className="relative h-10 input-component">
                      <select
                        {...register("companySize")}
                        id="company_size"
                        class=" border-[#E1E1E1] border-[1px] text-gray-900 text-sm block w-full p-2.5 text-[#717171] py-4 bg-transparent outline-none"
                      >
                        {companySizeOptions.map((items, id) => {
                          return <option key={id}>{items.value}</option>;
                        })}
                      </select>
                      <label
                        htmlFor="companySize"
                        className="absolute left-2 bg-white px-1 register-label font-Montserrat font-[300] text-base text-[#717171]"
                      >
                        <p>Company Size</p>
                      </label>
                    </div>
                  </>
                )}

                <button
                  type="submit"
                  className="border-[1px] w-[163px] h-[44px] font-Montserrat text-lg flex justify-center items-center rounded-3xl sm:py-6 bg-dark-green text-white font-[300] drop-shadow-xl"
                >
                  {isLoading ? <Loader /> : "REGISTER"}
                </button>
              </div>
            </div>
          </form>
        </div>
        <Faq titleSize="text-3xl" />
        <div className="space-y-8 pt-20 pb-48 md:py-24">
          <p className="text-[32px] text-center">
            ISO 9001 & ISO 27001 Certified Company
          </p>
          <div className="flex item-center justify-center space-x-4">
            <img src={ISO9001} alt="" />
            <img src={ISO27001} alt="" />
          </div>
        </div>
        <div>
          {/* <div className="shadow-2xl sm:shadow-black md:flex sm:justify-between bg-[#FFFFFF] items-center h-[150px] sm:h-[65px] fixed bottom-0 w-full"> */}

          {/* <div className="md:mr-24 ml-8 md:mt-1">
            <Link
              to="/landingpage/#signup-for-free"
              className=" bg-dark-green text-white px-[25px] py-[15px] text-center rounded-[40px] font-[20px] leading-[94px] no-underline" */}
          <div className="absolute">
            <div className="shadow-2xl shadow-black md:flex sm:justify-between bg-[#FFFFFF] items-center h-[100px] sm:h-[80px] fixed bottom-0 w-full overflow-hidden ">
              <p className="text-left md:ml-6 mr-24 font-[400] font-roboto md:text-[24px] text-[16px]  md:mt-0 mt-3 ml-8 text-[#000000] ">
                <TextTransition springConfig={presets.wobbly}>
                  {TEXTS[index % TEXTS.length]}
                </TextTransition>
              </p>
              <div className="md:mb-0 mb-3 md:ml-0 ml-6">
                <button
                  onClick={handleTop}
                  className=" bg-dark-green text-white w-44 h-12 md:mr-28  rounded-[40px] leading-[96px] ml-6"
                >
                  <p className="text-center text-lg"> Sign Up For Free </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
